export function slugify(section: string, options?: { replacement?: string }) {
  if (typeof section !== 'string') return '';

  return section
    ?.toLowerCase()
    .replace(/å/g, 'a')
    .replace(/ä/g, 'a')
    .replace(/ö/g, 'o')
    .replace(/[^a-zA-Z0-9\-\s!?]+/g, '')
    .replace(/[?!/]/g, '')
    .replace(/\s+/g, options?.replacement ?? '-');
}
