import { useAppState } from '@/hooks/useAppState';
import useLazyCallback from 'lib/hooks/useLazyCallback';

import { logger } from '@/utils/logger';
import { useCallback, useEffect, useState } from 'react';
import { useJWPlayer } from './JWPlayerContext';
import { setupPlayer } from './setup/setupPlayer';
import { VideoPlayerType } from './types/video';

export function VideoArticleJWPlayerComponent(
  props: VideoPlayerType & { className?: 'string'; videoPlacementPriority: string[] },
) {
  const [{ isSecondNativeArticleLoaded }] = useAppState();

  const { className, disableLazyLoad, videoPlacementPriority, ...restProps } = props;
  const [videoId, setVideoId] = useState(disableLazyLoad ? props.videoId : '');
  const { onPlayerSet, setVideoPlacementPriority } = useJWPlayer();

  const setVideoIdInView = useCallback(() => {
    if (!videoId) {
      setVideoId(props.videoId);
    }
  }, [props.videoId, videoId]);

  const videoRef = useLazyCallback<HTMLDivElement>(setVideoIdInView);

  const getPlayerInstance = useCallback(async () => {
    try {
      const instance = await setupPlayer({ ...restProps, isSecondNativeArticleLoaded });
      onPlayerSet(instance);
    } catch (error) {
      logger.catch('Error during setting up player instance', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPlayerSet]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoId && window.jwplayer) {
        clearInterval(interval);
        getPlayerInstance();
      }
    });
    return () => clearInterval(interval);
  }, [getPlayerInstance, videoId]);

  useEffect(() => {
    setVideoPlacementPriority(videoPlacementPriority);
  }, [videoPlacementPriority]);

  return <div ref={videoRef} className={className} id={videoId}></div>;
}
